<template>
    <div class="IntelligentWriting">
      <div class="home_header"></div>
      <div class="IntelligentWriting_mian">
        <div class="IntelligentWriting_header">
          专利OA智能答复
        </div>
        <div class="IntelligentWriting_card">
          <el-card class="box-card">
            <div @click="jump(0)">
              AI智能生成
            </div>
          </el-card>
          <el-card class="box-card">
            <div @click="jump(1)">
              人工辅助AI生成
            </div>
          </el-card>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
        name: "index",
      data(){
          return {

          }
      },
      methods:{
        jump(item){
          if(item == 0){
            this.$router.push({
              path: "/IntelligentWriting/AI"
            })
          }

        }
      }
    }
</script>

<style lang="less" scoped>
  .IntelligentWriting {
    .home_header {
      height: 90px;
    }
    .IntelligentWriting_mian {
      width: 1200px;
      margin: 30px auto;
      .IntelligentWriting_header {
        display: flex;
        justify-content: center;
        font-size: 24px;
        font-weight: 600;
      }
    }

    .IntelligentWriting_card {
      margin-top: 100px;
      justify-content: space-around;
      display: flex;
      width: 100%;
      .box-card {
        cursor: pointer;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 200px;

      }
    }
  }
</style>
